@import "node_modules/react-day-picker/lib/style";

.DayPickerInput-OverlayWrapper {
  z-index: 9999;
}
.DayPickerInput input[disabled] {
  background: #eceeef;
}

body {
  background: url(./assets/images/background.jpg) black no-repeat center 82px fixed;
  background-size: cover;
  color: white;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

button {
  border: 1px solid #bbb;
}

.btn {
  border-radius: 0;
  padding: 0.45rem 0.5rem;
  font-size: 80%;

  &.btn-primary {
    background-color: #fd9f27;
    border: none;
    font-weight: 100;
  }
}

.content {
  border: 20px solid #f9f9f9;
  background: #f9f9f9;
  color: #4a4a4a;
  font-weight: 100;

  h6 {
    margin-top: 30px;
    font-weight: 500;
    line-break: anywhere;
  }

  section {
    font-size: 14px;
    border: 1px solid #d5d3d6;
    background: white;
    padding: 15px;
  }
}

.form-group .currency {
  position: relative;

  input {
    padding-left: 20px;
  }

  &::before {
    content: "$";
    display: block;
    position: absolute;
    left: 5px;
    top: 3px;
  }
}

.state {
  g {
    fill: #d3d3d3;
    text {
      fill: #000000;
      font-size: 24px;
    }
  }
}

// print styles
@media print {
  body {
    background: white;
    color: black;
  }
  .content {
    border: none;
    background: white;
  }
  header,
  .noprint {
    display: none !important;
  }
}
