@import "../node_modules/react-datetime/css/react-datetime.css";

.rdt {
  width: 12.5rem;
  input {
    border: 1px solid #ced4da;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    margin-bottom: 5px;
  }
}

.App {
  header.main {
    height: 82px;

    img {
      height: 82px;
    }

    a {
      font-size: 0.7rem;
      color: white;

      &.nav-link {
        align-self: center;
      }
    }
  }

  .nav-tabs .nav-link {
    font-weight: 500;
    font-size: 1rem;
    &.active {
      background-color: transparent;
    }
  }
}

/* forms */
.checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.field-error {
  color: red;
  font-size: 11px;
}

.cse-billing #logo {
  margin-left: 10px;
}

.ModalClose {
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: 900;
  cursor: pointer;
  font-size: 30px;
  background: white;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transform: rotate(45deg);
  z-index: 9999;
}

.grid {
  display: grid;
  grid-gap: 1rem;
}

.grid-row {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

.grid-flow-col {
  display: grid;
  grid-auto-flow: column;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.gap-2 {
  gap: 0.5rem;
}

.m-0 {
  margin: 0;
}

